$(document).on('change', "#team_member", function () {
  var status = ($(this).val() == 'false' ? 'true' : 'false')
  $.ajax({
    url: '/groups/'+ $(this).data('id')+ '/update_group_status' + '?active=' + status,
    type: "PUT"
  });
});
  
$(document).on('click', ".remove", function () {
  var id = $(this).data("id");
  var ele = $(this).closest('.add-members');
  $(this).parent().find('input:hidden:first').val(true);
  ele.remove();
  
  $('.condition-field').removeClass('disable-btn');
})

$(document).on('click', ".group_remove", function () {
  var id = $(this).data("id");
  var ele = $(this).closest('.add-members');
  $(this).parent().find('input:hidden:first').val(true);
  ele.hide();
  
  $('.condition-field').removeClass('disable-btn');
})

class addFields {
  constructor() {
    this.links = document.querySelectorAll('.add_fields')
    this.iterateLinks()
  }

  iterateLinks() {
    if (this.links.length === 0) return
    this.links.forEach(link => {
      link.addEventListener('click', e => {
        this.handleClick(link, e)
      })
    })
  }

  handleClick(link, e) {
    if (!link || !e) return
    e.preventDefault()
    let time = new Date().getTime()
    let linkId = link.dataset.id
    let regexp = linkId ? new RegExp(linkId, 'g') : null
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
    newFields ? link.insertAdjacentHTML('afterend', newFields) : null
  }
}

window.addEventListener('turbolinks:load', () => new addFields())


